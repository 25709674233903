
import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/Common/Header.vue"; // @ is an
import Footer from "@/components/Common/Footer.vue"; // @ is an

@Component({
  components: {
    Header,
    Footer
  }
})
export default class BaseLayout extends Vue {}
